exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-refresh-alert-js": () => import("./../../../src/pages/refresh-alert.js" /* webpackChunkName: "component---src-pages-refresh-alert-js" */),
  "component---src-templates-auxiliary-page-js": () => import("./../../../src/templates/auxiliaryPage.js" /* webpackChunkName: "component---src-templates-auxiliary-page-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-financial-service-js": () => import("./../../../src/templates/financialService.js" /* webpackChunkName: "component---src-templates-financial-service-js" */),
  "component---src-templates-future-of-mobility-js": () => import("./../../../src/templates/futureOfMobility.js" /* webpackChunkName: "component---src-templates-future-of-mobility-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-in-the-news-details-js": () => import("./../../../src/templates/inTheNewsDetails.js" /* webpackChunkName: "component---src-templates-in-the-news-details-js" */),
  "component---src-templates-in-the-news-listing-js": () => import("./../../../src/templates/inTheNewsListing.js" /* webpackChunkName: "component---src-templates-in-the-news-listing-js" */),
  "component---src-templates-insights-details-js": () => import("./../../../src/templates/insightsDetails.js" /* webpackChunkName: "component---src-templates-insights-details-js" */),
  "component---src-templates-insights-listing-js": () => import("./../../../src/templates/insightsListing.js" /* webpackChunkName: "component---src-templates-insights-listing-js" */),
  "component---src-templates-motor-sport-detail-js": () => import("./../../../src/templates/motorSportDetail.js" /* webpackChunkName: "component---src-templates-motor-sport-detail-js" */),
  "component---src-templates-news-detail-page-js": () => import("./../../../src/templates/newsDetailPage.js" /* webpackChunkName: "component---src-templates-news-detail-page-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/newsListing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-our-brands-detail-js": () => import("./../../../src/templates/ourBrandsDetail.js" /* webpackChunkName: "component---src-templates-our-brands-detail-js" */),
  "component---src-templates-our-markets-detail-js": () => import("./../../../src/templates/ourMarketsDetail.js" /* webpackChunkName: "component---src-templates-our-markets-detail-js" */),
  "component---src-templates-our-people-landing-js": () => import("./../../../src/templates/ourPeopleLanding.js" /* webpackChunkName: "component---src-templates-our-people-landing-js" */),
  "component---src-templates-our-story-page-js": () => import("./../../../src/templates/ourStoryPage.js" /* webpackChunkName: "component---src-templates-our-story-page-js" */),
  "component---src-templates-our-values-page-js": () => import("./../../../src/templates/ourValuesPage.js" /* webpackChunkName: "component---src-templates-our-values-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-quick-read-details-js": () => import("./../../../src/templates/quickReadDetails.js" /* webpackChunkName: "component---src-templates-quick-read-details-js" */),
  "component---src-templates-quick-reads-listing-js": () => import("./../../../src/templates/quickReadsListing.js" /* webpackChunkName: "component---src-templates-quick-reads-listing-js" */),
  "component---src-templates-site-map-js": () => import("./../../../src/templates/siteMap.js" /* webpackChunkName: "component---src-templates-site-map-js" */),
  "component---src-templates-who-we-are-detail-page-js": () => import("./../../../src/templates/whoWeAreDetailPage.js" /* webpackChunkName: "component---src-templates-who-we-are-detail-page-js" */)
}

